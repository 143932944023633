import hexRgb from 'hex-rgb';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Breakpoints } from 'styles/theme';

type TabItem<T> = {
    id: T;
    label: string;
};

type Props<T> = {
    items: TabItem<T>[];
    value: string;
    onChange: (id: T) => void;
    name: string;
};

type TabProps<T> = {
    item: TabItem<T>;
    onChange: (value: T) => void;
    isSelected: boolean;
    name: string;
};

const StyledTab = styled.label<{ isSelected: boolean }>`
    background: ${({ theme, isSelected }) =>
        isSelected
            ? hexRgb(theme.colors.primary, { alpha: 0.2, format: 'css' })
            : hexRgb(theme.colors.primary, { alpha: 0.6, format: 'css' })};
    padding: 15px 22px 0 22px;
    height: 42px;
    display: flex;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    flex-grow: 1;
    input {
        display: none;
    }

    &:not(:last-child) {
        border-right: 3px solid white;
    }

    @media ${Breakpoints.md} {
        padding: 0 22px;
        flex-grow: 0;
        width: 145px;
    }
`;

const Tab = <T extends string>({ name, item, onChange, isSelected }: TabProps<T>) => {
    const handleChange = useCallback(() => {
        onChange(item.id);
    }, [item, onChange]);
    return (
        <StyledTab isSelected={isSelected}>
            <span>{item.label}</span>
            <input name={name} checked={isSelected} type="radio" value={item.id} onChange={handleChange} />
        </StyledTab>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: stretch;
    width: 100%;

    @media ${Breakpoints.md} {
        justify-content: flex-start;
    }
`;

export const Tabs = <T extends string>({ name, items, value, onChange }: Props<T>): React.ReactElement => {
    return (
        <Container>
            {items.map((item) => (
                <Tab name={name} key={item.id} item={item} isSelected={value === item.id} onChange={onChange} />
            ))}
        </Container>
    );
};
