import { Button } from 'components/atoms/Button';
import hexRgb from 'hex-rgb';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Breakpoints } from 'styles/theme';

const ShippingContent = styled.div`
    background: ${({ theme }) => hexRgb(theme.colors.primary, { alpha: 0.2, format: 'css' })};
    margin: 0 -28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    align-self: stretch;

    @media ${Breakpoints.lg} {
        margin: 0;
        background: none;
        padding: 40px 0;
    }
`;

const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.colors.danger};
    text-align: center;
    margin-bottom: 1rem;
`;

type Props = {
    error: string;
    inProgress: boolean;
};

export const CalculatorAction: React.FunctionComponent<Props> = ({ inProgress, error }: Props) => {
    const { t } = useTranslation('shipping');

    return (
        <ShippingContent>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Button type="submit" size="lg" fill="solid" kind="secondary" inProgress={inProgress}>
                {t('Show Prices')}
            </Button>
        </ShippingContent>
    );
};
