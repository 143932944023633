import { FormRow } from 'components/atoms/FormRow';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { DimensionsField } from './DimensionsField';
import { WeightField } from './WeightField';
import { Breakpoints } from 'styles/theme';
import { IconButton } from 'components/atoms/IconButton';
import { BoxPresetOption, BoxPresetSelect } from 'components/parts/dashboard/BoxPresetSelect';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ShipmentInput } from '@magicship/utils/shipment';

type BoxItemProps = {
    index: number;
    onRemove: (index: number) => void;
    onDuplicate: (index: number) => void;
    username?: string;
    presetPlaceholder?: string;
};

const Container = styled.div`
    display: flex;
    align-items: flex-start;

    &:not(:first-child) {
        padding-top: 10px;
        border-top: 2px solid ${({ theme }) => theme.colors.primary};
    }
`;

const ItemDataContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    @media ${Breakpoints.md} {
        margin-right: 0;
        flex-direction: row;

        > div {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
`;

const ActionsContainer = styled.div<{ tall?: boolean }>`
    margin-right: 10px;
    flex-shrink: 0;
    flex-direction: column;
    display: flex;
    height: 100%;
    align-self: end;
    margin-bottom: 34px;
    gap: 28px;
`;

export const BoxItem: React.FunctionComponent<BoxItemProps> = ({
    onRemove,
    onDuplicate,
    index,
    username,
    presetPlaceholder,
}: BoxItemProps) => {
    const { t } = useTranslation('shipping');
    const { setValue, getValues } = useFormContext<ShipmentInput>();
    const [boxPreset, setBoxPreset] = useState<BoxPresetOption | null>(null);
    const handleChangePreset = useCallback(
        (option: BoxPresetOption | null) => {
            if (option) {
                setBoxPreset(option);
                setValue(
                    `boxes.${index}`,
                    {
                        id: getValues(`boxes.${index}.id`),
                        weight: { unit: option.weight.unit, value: option.weight.value.toString() },
                        dimensions: {
                            unit: option.dimensions.unit,
                            width: option.dimensions.width.toString(),
                            height: option.dimensions.height.toString(),
                            length: option.dimensions.length.toString(),
                        },
                    },
                    {
                        shouldDirty: true,
                        shouldValidate: true,
                        shouldTouch: true,
                    }
                );
            }
        },
        [setValue, getValues, index]
    );
    return (
        <Container>
            <ActionsContainer tall={Boolean(username)}>
                <IconButton icon="clone" onClick={() => onDuplicate(index)} />
                <IconButton icon="remove" onClick={() => onRemove(index)} />
            </ActionsContainer>
            <div>
                {username && (
                    <BoxPresetSelect
                        value={boxPreset}
                        onChange={handleChangePreset}
                        name={`box-preset-${index}`}
                        username={username}
                        placeholder={presetPlaceholder}
                    />
                )}
                <ItemDataContainer>
                    <FormRow label={t('Size')}>
                        <DimensionsField index={index} />
                    </FormRow>
                    <FormRow label={t('Weight')}>
                        <WeightField index={index} />
                    </FormRow>
                </ItemDataContainer>
            </div>
        </Container>
    );
};
