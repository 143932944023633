import isPostalCode from 'validator/lib/isPostalCode';
import { Loader } from '@googlemaps/js-api-loader';

type AddressComponent = {
    long_name: string;
    short_name: string;
    types: string[];
};

type Result = {
    countryCode: string;
    stateCode: string;
    city: string;
};

const loader = new Loader({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY || '',
    version: 'weekly',
    libraries: [],
});

const findLocation = async ({
    city,
    postalCode,
    countryCode,
}: {
    city?: string;
    postalCode?: string;
    countryCode?: string;
}) => {
    await loader.load();
    const geocoder = new google.maps.Geocoder();
    try {
        const result = await geocoder.geocode({
            componentRestrictions: {
                country: countryCode,
                postalCode,
                locality: city,
            },
        });
        if (result.results?.length > 0) {
            const findCountryComponent = (y: AddressComponent) => y.types.indexOf('country') !== -1;
            const findStateProvinceComponent = (y: AddressComponent) =>
                y.types.indexOf('administrative_area_level_1') !== -1;
            const findCityComponent = (y: AddressComponent) => y.types.indexOf('locality') !== -1;
            const findSublocalityComponent = (y: AddressComponent) => y.types.indexOf('sublocality') !== -1;
            const resultItem = result.results.find((x) => x.address_components.find(findCountryComponent));
            if (resultItem) {
                const countryCode = resultItem.address_components.find(findCountryComponent)?.short_name ?? null;
                if (countryCode) {
                    const stateCode = resultItem.address_components.find(findStateProvinceComponent)?.short_name ?? '';
                    let city = resultItem.address_components.find(findCityComponent)?.long_name ?? '';
                    if (!city) {
                        city = resultItem.address_components.find(findSublocalityComponent)?.long_name ?? '';
                    }
                    return {
                        countryCode,
                        stateCode,
                        city,
                    };
                }
            }
        }
    } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((e as any).code === 'ZERO_RESULTS') {
            return null;
        }
        throw e;
    }
    return null;
};

export const findLocationByCity = async (city: string, countryCode?: string): Promise<Result | null> => {
    return findLocation({ city, countryCode });
};

export const findLocationByPostalCode = async (postalCode: string, countryCode?: string): Promise<Result | null> => {
    const result = await findLocation({ postalCode, countryCode });
    if (!result) {
        // Sometimes Google API can not figure out country by just postal code
        if (isPostalCode(postalCode, 'CA')) {
            return await findLocation({ postalCode, countryCode: 'CA' });
        }
    }
    return result;
};
