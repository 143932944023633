import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import { BoxPreset, BoxPresetsByOwnerQuery, BoxPresetsByOwnerQueryVariables } from '~graphql/API';
import { Select } from 'components/atoms/Select';
import { API } from '@aws-amplify/api';
import * as queries from 'src/graphql/queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';

export type BoxPresetOption = Omit<BoxPreset, '__typename' | 'owner'>;

type Props = {
    value: BoxPresetOption | null;
    onChange: (value: BoxPresetOption | null) => void;
    onBlur?: (e: React.FocusEvent) => void;
    name?: string;
    error?: boolean;
    username: string;
    placeholder?: string;
};

export const BoxPresetSelect: React.FunctionComponent<Props> = ({
    error,
    value,
    onChange,
    onBlur,
    name,
    username,
    placeholder,
}: Props) => {
    const { t } = useTranslation('shipping');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<BoxPresetOption[]>([]);
    const loadOptions = useCallback(async () => {
        const variables: BoxPresetsByOwnerQueryVariables = {
            owner: username,
        };
        setIsLoading(true);
        try {
            const boxesResult = (await API.graphql({
                query: queries.boxPresetsByOwner,
                variables,
            })) as GraphQLResult<BoxPresetsByOwnerQuery>;
            const boxes = boxesResult.data?.boxPresetsByOwner?.items as BoxPreset[];
            boxes.sort((x, y) => x.alias.localeCompare(y.alias));
            setOptions(boxes);
        } catch (e) {}
        setIsLoading(false);
    }, [username]);
    const handleFocus = useCallback(() => {
        if (!options.length && !isLoading) {
            loadOptions();
        }
    }, [options, isLoading, loadOptions]);

    return (
        <Select
            id={name}
            instanceId={name}
            contrast
            name={name}
            error={error}
            placeholder={placeholder ?? t('Select a saved box')}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            value={value}
            onFocus={handleFocus}
            isLoading={isLoading}
            getOptionLabel={(option) => option.alias}
            getOptionValue={(option) => option.id}
            noOptionsMessage={() =>
                t('You have no pre-saved boxes. They will show up here when you add some, under the Settings section. ')
            }
        />
    );
};
