import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { Select } from './Select';
import { allCountries } from 'country-region-data';

type Props = {
    value: string | null;
    onChange: (value: string | null) => void;
    onBlur?: (e: React.FocusEvent) => void;
    name?: string;
    inputRef?: React.Ref<unknown>;
    error?: boolean;
    values?: string[];
};

export const CountrySelect: React.FunctionComponent<Props> = ({
    inputRef,
    error,
    value,
    onChange,
    onBlur,
    name,
    values,
}: Props) => {
    const { t } = useTranslation('common');
    const options = useMemo(() => {
        const countries = allCountries.map(([name, slug]) => ({
            label: name,
            value: slug,
        }));
        if (values) {
            return countries.filter((x) => values.includes(x.value));
        }
        return countries;
    }, [values]);
    const selectedOption = useMemo(() => options.find((x) => x.value === value), [options, value]);

    return (
        <Select
            id={name}
            instanceId={name}
            error={error}
            inputRef={inputRef}
            placeholder={t('Country')}
            name={name}
            onBlur={onBlur}
            options={options}
            value={selectedOption}
            onChange={(code) => {
                onChange(code?.value ?? null);
            }}
        />
    );
};
