import { Tabs } from 'components/atoms/Tabs';
import hexRgb from 'hex-rgb';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { BoxForm } from './BoxForm';
import { SectionTitle } from './SectionTitle';
import { NegativeOffset } from 'components/atoms/Content';
import { PackageType, ShipOrderInput } from 'src/API';

const Content = styled.div`
    background: ${({ theme }) => hexRgb(theme.colors.primary, { alpha: 0.2, format: 'css' })};
    padding: 24px 15px;
    min-height: 220px;
`;

const Container = styled.div`
    width: 100%;
`;

type Props = {
    showTitle?: boolean;
    username?: string;
    presetPlaceholder?: string;
};

export const ShippingSection: React.FunctionComponent<Props> = ({
    showTitle = true,
    username,
    presetPlaceholder,
}: Props) => {
    const { t } = useTranslation('shipping');
    const { control } = useFormContext<ShipOrderInput>();
    const items = useMemo(
        () => [
            {
                id: PackageType.box,
                label: t(`Box(es)`),
            },
        ],
        [t]
    );
    return (
        <Container>
            {showTitle && <SectionTitle>{t('Shipping')}</SectionTitle>}
            <NegativeOffset>
                <Controller
                    render={({ field }) => (
                        <Tabs name={field.name} onChange={field.onChange} items={items} value={field.value} />
                    )}
                    control={control}
                    name="packageType"
                />
                <Content>
                    <BoxForm presetPlaceholder={presetPlaceholder} username={username} />
                </Content>
            </NegativeOffset>
        </Container>
    );
};
