import { Button } from 'components/atoms/Button';
import { useTranslation } from 'next-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Breakpoints } from 'styles/theme';
import { BoxItem } from './BoxItem';
import { makeBoxInput, ShipmentInput } from '@magicship/utils/shipment';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    > button {
        align-self: center;
        color: ${({ theme }) => theme.colors.secondary};
        margin: 10px 0;

        @media ${Breakpoints.md} {
            align-self: flex-start;
        }
    }
`;

type Props = {
    username?: string;
    presetPlaceholder?: string;
};

export const BoxForm: React.FunctionComponent<Props> = ({ username, presetPlaceholder }: Props) => {
    const { control, clearErrors, getValues } = useFormContext<ShipmentInput>();
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'boxes',
    });
    const handleAdd = () => {
        const boxTemplate = fields[0];
        const newBox = makeBoxInput(boxTemplate.dimensions.unit, boxTemplate.weight.unit);
        append(newBox, { shouldFocus: false });
    };
    const handleRemove = (index: number) => {
        const boxTemplate = fields[0];
        const lengthUnit = boxTemplate.dimensions.unit;
        const weightUnit = boxTemplate.weight.unit;
        if (fields.length > 1) {
            remove(index);
        } else {
            update(0, makeBoxInput(lengthUnit, weightUnit));
            clearErrors('boxes');
        }
    };
    const handleDuplicate = (index: number) => {
        const box = getValues().boxes[index];
        const newBox = makeBoxInput(box.dimensions.unit, box.weight.unit);
        newBox.dimensions = { ...box.dimensions };
        newBox.weight = { ...box.weight };
        append(newBox, { shouldFocus: false });
    };
    const { t } = useTranslation('shipping');
    return (
        <Container>
            <div>
                {fields.map((field, index) => (
                    <BoxItem
                        presetPlaceholder={presetPlaceholder}
                        key={field.id}
                        index={index}
                        onRemove={handleRemove}
                        onDuplicate={handleDuplicate}
                        username={username}
                    />
                ))}
            </div>
            <Button kind="secondary" fill="solid" type="button" onClick={handleAdd}>
                {t('Add Another Box')}
            </Button>
        </Container>
    );
};
