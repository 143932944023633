import upsLogo from 'public/carriers/UPS.png';
import canadaPostLogo from 'public/carriers/CanadaPost.png';
import icsLogo from 'public/carriers/ICS.png';
import canparLogo from 'public/carriers/Canpar.svg';
import { ShippingService } from '~graphql/API';

export const carrierLogoMap: Record<ShippingService, StaticImageData> = {
    [ShippingService.UPS]: upsLogo,
    [ShippingService.CanadaPost]: canadaPostLogo,
    [ShippingService.ICS]: icsLogo,
    [ShippingService.Canpar]: canparLogo,
};
