import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { RadioInput } from 'components/atoms/RadioInput';
import { carrierLogoMap } from './common';
import Image from 'next/image';
import hexRgb from 'hex-rgb';
import { formatMoney } from '@magicship/utils/money';
import { ShippingOption } from '~graphql/API';
import { useCallback } from 'react';

const ItemsTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            border-bottom: 2px solid ${({ theme }) => hexRgb(theme.colors.secondary, { alpha: 0.4, format: 'css' })};
        }
    }

    th {
        text-align: left;
        text-transform: uppercase;
        font-weight: 700;
        padding-bottom: 5px;
    }

    td {
        vertical-align: center;
        padding: 10px 0;
    }
`;

const SpeedCell = styled.td`
    width: 160px;
`;

const CompanyCell = styled.td`
    width: 160px;
`;

const StyledRadioButton = styled(RadioInput)`
    margin-top: 2px;
`;

const RadioCell = styled.td`
    min-width: 40px;
    width: 40px;
`;

const NameCell = styled.td`
    cursor: pointer;
    width: 270px;
`;

const StyledRow = styled.tr`
    cursor: pointer;
`;

const AliasCell = styled.td`
    width: 270px;
`;

const PriceCell = styled.td`
    width: 200px;
`;

const StyledOriginalPrice = styled.span`
    opacity: 0.5;
    text-decoration: line-through;
    margin-left: 1rem;
`;

type Props = {
    data: ShippingOption[];
    selectedOption: ShippingOption | null;
    onChange: (option: ShippingOption) => void;
    disabled?: boolean;
    showOriginalPrice?: boolean;
    hideAlias?: boolean;
};

export const ShippingOptionsTable: React.FunctionComponent<Props> = ({
    data,
    selectedOption,
    onChange,
    disabled,
    showOriginalPrice,
    hideAlias,
}: Props) => {
    const { t } = useTranslation('shipping');
    const handleChange = useCallback(
        (x: ShippingOption) => {
            if (!disabled) {
                onChange(x);
            }
        },
        [disabled, onChange]
    );
    return (
        <ItemsTable>
            <thead>
                <tr>
                    <th></th>
                    <th>{t('type')}</th>
                    <th>{t('speed')}</th>
                    {!hideAlias && <th>{t('alias')}</th>}
                    <th>{t('company')}</th>
                    <th>{t('price')}</th>
                </tr>
            </thead>
            <tbody>
                {data.map((x, index) => {
                    const code = `${x.providerId}-${x.service}-${x.serviceCode}`;
                    const logo = carrierLogoMap[x.service];
                    const isSelected =
                        x.providerId === selectedOption?.providerId &&
                        x.service === selectedOption?.service &&
                        x.serviceCode === selectedOption?.serviceCode;
                    return (
                        <StyledRow key={code} onClick={() => handleChange(x)}>
                            <RadioCell>
                                <StyledRadioButton
                                    kind="secondary"
                                    name="service-option-table"
                                    value={index.toString()}
                                    onChange={() => handleChange(x)}
                                    isSelected={isSelected}
                                    fill="contrast"
                                />
                            </RadioCell>
                            <NameCell>{x.serviceName}</NameCell>
                            <SpeedCell>
                                {x.daysInTransit
                                    ? `${x.daysInTransit} ${x.daysInTransit === '1' ? t('day') : t('days')}`
                                    : ''}
                            </SpeedCell>
                            {!hideAlias && <AliasCell>{x.providerName ?? ''}</AliasCell>}
                            <CompanyCell>
                                {logo && (
                                    <Image src={logo} alt={x.service} width="132" height="68" objectFit="contain" />
                                )}
                            </CompanyCell>
                            <PriceCell>
                                {formatMoney(x.price)}{' '}
                                {showOriginalPrice && (
                                    <StyledOriginalPrice>
                                        {x.originalPrice ? formatMoney(x.originalPrice) : ''}
                                    </StyledOriginalPrice>
                                )}
                            </PriceCell>
                        </StyledRow>
                    );
                })}
            </tbody>
        </ItemsTable>
    );
};
